export default class AddressHelper {

    static isEqual(a, b) {
        if (a === b) return true;

        const labelA = a ? a.label : null;
        const labelB = b ? b.label : null;

        if (labelA === labelB) return true;

        // Unable to use placeId from Mapbox, because it is not unique
        // For example, 115 and 116 Alexander St Crows Nest have the same Id in mapbox

        // const placeIdA = a ? a.placeId : null;
        // const placeIdB = b ? b.placeId : null;

        // if (placeIdA == placeIdB) return true;
    }

    static cleanUpFormattedAddress(str, components) {
        if (!str) {
            return '';
        }

        const parts = str.split(', ');
        if (parts.length < 2) {
            return str;
        }

        // Remove country name
        const country = parts[parts.length - 1];
        let res = str.substring(0, str.indexOf(`, ${country}`));

        // Remove location name
        const locationName = parts[0];
        if (components.locationName && (
            locationName === components.locationName.long_name
            || locationName === components.locationName.short_name
        )) {
            res = res.substring(`${locationName}, `.length, res.length);
        }

        return res;
    }

    static map(gmaps, appCountry) {
        if (!gmaps || !gmaps.place_id) {
            return null;
        }

        const location = {
            lat: gmaps.geometry.location.lat(),
            lng: gmaps.geometry.location.lng()
        };

        const components = this.getAddressComponentDictionary(gmaps.address_components);
        const res = {
            placeId: gmaps.place_id,
            latitude: location.lat,
            longitude: location.lng,
            unit: this.mapAddressComponent(components.unit),
            streetNumber: this.mapAddressComponent(components.streetNumber),
            street: this.mapAddressComponent(components.street),
            city: this.mapAddressComponent(components.city),
            state: this.mapAddressComponent(components.state),
            postcode: this.mapAddressComponent(components.postcode),
            country: this.mapAddressComponent(components.country),
            types: gmaps.types,
            partialMatch: gmaps.partial_match
        };
        if (!res.city) {
            res.city = this.mapAddressComponent(components.colloquial);
        }
        this.FixGMapsComponents(res, appCountry);
        res.label = this.getLabel(res);
        return res;
    }

    static FixGMapsComponents(address, appCountry) {
        // Set the state in case it's not correctly defined by Google.
        // For example some territories in Australia: Christams Island, Cocos Island, Norfolk Island
        if (this.isOtherState(appCountry, (address.country || {}).shortName)) {
            if (!address.city) {
                address.city = address.country;
            }
            if (!address.state) {
                address.state = {
                    shortName: null,
                    longName: null
                };
            }
            address.country = {
                shortName: appCountry.toUpperCase(),
                longName: this.getCountryLongName(appCountry)
            };
        }
        this.FixForJbt(address, appCountry);
    }

    static FixForJbt(address, appCountry) {
        if (appCountry.toUpperCase() === 'AU' && address.state &&
            address.state.shortName && address.state.shortName.toUpperCase() === 'JBT') {
            address.state = {
                shortName: 'NSW',
                longName: null
            };
            address.postcode = {
                shortName: '2540',
                longName: '2540'
            };
            if (address.placeId === 'ChIJRWcFsaSIFGsR8NZDkLQJBgQ') {
                address.city = {
                    shortName: 'Jervis Bay',
                    longName: 'Jervis Bay'
                };
            }
        }
        if (appCountry.toUpperCase() === 'AU' && address.placeId === 'ChIJCz4U9E2OFGsRFI-_VVoFsaE') {
            address.state = {
                shortName: 'NSW',
                longName: null
            };
            address.postcode = {
                shortName: '2540',
                longName: '2540'
            };
            address.latitude = -35.149092;
            address.longitude = 150.69611540000005;
        }

    }

    static getLabel(address) {
        if (!address) {
            return '';
        }

        if (
            !address.unit
            && !address.streetNumber
            && !address.street
            && !address.city
            && !address.postcode
        ) {
            return address.state
                ? this.getComponentValue(address.state)
                : this.getComponentValue(address.country);
        }

        const isNullOrWhiteSpace = p => !p || p.trim() === '';

        let label = '';
        const appendWithSpaceIfNeeded = p => {
            if (!isNullOrWhiteSpace(p)) {
                if (!isNullOrWhiteSpace(label)) {
                    label += ' ';
                }
                label += p;
            }
        };

        if (address.unit) {
            label += this.getComponentValue(address.unit);
        }

        if (address.streetNumber) {
            if (!isNullOrWhiteSpace(label)) {
                label += '/';
            }
            label += this.getComponentValue(address.streetNumber);
        }

        appendWithSpaceIfNeeded(this.getComponentValue(address.street, true));

        if (!isNullOrWhiteSpace(label)) {
            label += ',';
        }

        appendWithSpaceIfNeeded(this.getComponentValue(address.city));
        appendWithSpaceIfNeeded(this.getComponentValue(address.state, true));
        appendWithSpaceIfNeeded(this.getComponentValue(address.postcode));

        return label;
    }

    static getComponentValue(component, preferShorter) {
        if (!component)
            return '';
        if (preferShorter === true && component.shortName && component.shortName.length > 0)
            return component.shortName;
        return component.longName;
    }

    static handleCountryName(gmaps, components) {
        const country = components.country;
        const countryName = country.long_name || this.getCountryLongName(country.short_name);
        // HACK: Sometimes the 'formatted_address' value returned by Google only has the country name
        // so we use the 'name' property instead
        return gmaps.formatted_address === countryName && gmaps.name !== countryName
            ? gmaps.name
            : gmaps.formatted_address;
    }

    static getCountryLongName(shortName) {
        if (shortName.toLowerCase() === 'au') {
            return 'Australia';
        }
        if (shortName.toLowerCase() === 'ca') {
            return 'Canada';
        }
        if (shortName.toLowerCase() === 'us') {
            return 'United States';
        }
    }

    static removeUnitName(formattedAddress, components) {
        var regex = new RegExp(`(${components.unit.long_name})[\\s|-|/]+`);
        return formattedAddress.replace(regex, '');
    }

    // See https://developers.google.com/maps/documentation/geocoding/intro#Types
    static getAddressComponentType(types) {
        if (types.indexOf('colloquial_area') > -1) {
            return 'colloquial';
        }

        if (types.indexOf('premise') > -1) {
            return 'locationName';
        }

        if (types.indexOf('subpremise') > -1) {
            return 'unit';
        }

        if (types.indexOf('street_number') > -1) {
            return 'streetNumber';
        }

        if (types.indexOf('route') > -1) {
            return 'street';
        }

        if (types.indexOf('locality') > -1 && types.indexOf('colloquial_area') === -1) {
            return 'city';
        }

        if (types.indexOf('administrative_area_level_1') > -1) {
            return 'state';
        }

        if (types.indexOf('postal_code') > -1) {
            return 'postcode';
        }

        if (types.indexOf('country') > -1) {
            return 'country';
        }

        return null;
    }

    static getAddressComponentDictionary(addressComponents) {
        const res = {};
        addressComponents.forEach(function (item) {
            const type = this.getAddressComponentType(item.types);
            if (type) {
                res[type] = item;
            }
        }, this);
        return res;
    }

    static mapAddressComponent(component) {
        return component ? {
            shortName: component.short_name,
            longName: component.long_name
        } : null;
    }

    static isSameState(gmaps, expectedTerms, appCountry) {
        if (!expectedTerms || expectedTerms.length < 1) {
            return true;
        }

        const components = this.getAddressComponentDictionary(gmaps.address_components);
        const actual = this.isOtherState(appCountry, (components.country || {}).short_name) && !components.state
            ? components.country || {}
            : components.state || {};

        return expectedTerms.some(term => {
            if (!term || !term.value) {
                return false;
            }

            const expected = term.value.toLowerCase();
            return (actual.short_name || '').toLowerCase() === expected ||
                (actual.long_name || '').toLowerCase() === expected;
        });
    }

    static isEqualCountry(gmaps, countryCode) {
        if (!gmaps) {
            return true;
        }

        const components = this.getAddressComponentDictionary(gmaps.address_components);
        if (!components || !components.country) {
            return false;
        }

        const gmapsCountryCodes = this.getGmapsCountryCodes(countryCode);
        const gmapsCountryCode = (components.country.short_name || '').toUpperCase();
        return gmapsCountryCodes.indexOf(gmapsCountryCode) > -1;
    }

    static isCountry(address) {
        return !address ||
            !address.unit &&
            !address.streetNumber &&
            !address.street &&
            !address.city &&
            !address.postcode &&
            !address.state
        ;
    }

    static getGmapsCountryCodes(country) {
        if (!country) {
            return null;
        }

        const countryCode = country.toUpperCase();
        if (countryCode === 'AU') {

            // Include Australian territories which Google treats as separate country codes
            const christmasIsland = 'CX';
            const cocosIsland = 'CC';
            const norfolkIsland = 'NF';

            return [
                countryCode,
                christmasIsland,
                cocosIsland,
                norfolkIsland
            ];
        }

        return [country.toUpperCase()];
    }

    static isOtherState(appCountry, addressCountry) {
        if (!addressCountry) {
            return false;
        }

        const addressCountryUpper = addressCountry.toUpperCase();

        if (appCountry.toUpperCase() === 'AU') {
            return addressCountryUpper === 'CX' ||
                addressCountryUpper === 'CC' ||
                addressCountryUpper === 'NF';
        }

        return false;
    }
}
