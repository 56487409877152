import { actions } from '../../../js/redux/sharedSlice';
import { dispatch } from '../../../js/redux/store';

const onAddressCookieInit = address =>
    dispatch(
        actions.addressCookieInit({
            address: JSON.parse(address)
        })
    );

export default class SearchAddressActions {
    static setAddressCookie(value) {
        if (typeof window === 'undefined' || !window || !wo$) {
            return;
        }

        if (!value) {
            Cookies.set('address', null, { path: '/', expires: -1 });
            return;
        }

        const newValue = JSON.stringify({
            placeId: value.placeId,
            label: value.label,
            latitude: value.latitude,
            longitude: value.longitude,
            unit: value.unit,
            streetNumber: value.streetNumber,
            street: value.street,
            city: value.city,
            state: value.state,
            postcode: value.postcode,
            types: value.types,
            partialMatch: value.partialMatch
        });

        const current = window.Cookies.get('address');
        if (newValue === current) {
            onAddressCookieInit(current);
            return;
        }

        Cookies.set('address', newValue, { path: '/', expires: 1 });
        onAddressCookieInit(newValue);
    }

    static invalidateAddressCookie(value) {
        if (typeof window === 'undefined' || !window || !wo$) {
            return;
        }

        if (!value) {
            Cookies.set('address', null, { path: '/', expires: -1 });
            return;
        }

        const current = window.Cookies.get('address');
        if (current) {
            const currentValue = JSON.parse(current);
            if (currentValue && currentValue.label !== value) {
                Cookies.set('address', null, { path: '/', expires: -1 });
            }
        }
    }
}
