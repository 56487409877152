export default class StateNames {
    constructor() {
        this.abbreviate = this.abbreviate.bind(this);
    }

    static abbreviate(name, country) {
        if (!name || name.length === 0) {
            return name;
        }

        if (country && country.length > 0) {
            const countryStates = this._states
                .filter(p => p.country.toLowerCase() === country.toLowerCase())[0];

            if (countryStates) {
                const match = this.getMatch(countryStates.states, name);
                if (match) {
                    return match[0];
                }
            }
        }

        // All countries
        for (let i = 0; i < this._states.length; i++) {
            if (country && country.length > 0 && this._states[i].country.toLowerCase() === country.toLowerCase()) {
                // Already checked
                continue;
            }

            const match = this.getMatch(this._states[i].states, name);
            if (match) {
                return match[0];
            }
        }
    }

    static getMatch(items, name) {
        return items.filter(p => p[1].toLowerCase() === name.toLowerCase())[0];
    }
}

/*
    SELECT
    '[ ''' + [Code] + ''', ''' + [Name] + ''' ],'
    FROM [dbo].[State]
*/
StateNames._states = [
    {
        country: 'au',
        states: [
            ['ACT', 'Australian Capital Territory'],
            ['NSW', 'New South Wales'],
            ['NT', 'Northern Territory'],
            ['QLD', 'Queensland'],
            ['SA', 'South Australia'],
            ['TAS', 'Tasmania'],
            ['VIC', 'Victoria'],
            ['WA', 'Western Australia']
        ]
    },
    {
        country: 'ca',
        states: [
            ['AB', 'Alberta'],
            ['BC', 'British Columbia'],
            ['MB', 'Manitoba'],
            ['NB', 'New Brunswick'],
            ['NL', 'Newfoundland and Labrador'],
            ['NS', 'Nova Scotia'],
            ['ON', 'Ontario'],
            ['PE', 'Prince Edward Island'],
            ['QC', 'Quebec'],
            ['SK', 'Saskatchewan'],
            ['NT', 'Northwest Territories'],
            ['NU', 'Nunavut'],
            ['YT', 'Yukon Territory']
        ]
    },
    {
        country: 'mx',
        states: [
            ['AGU', 'Aguascalientes'],
            ['BCN', 'Baja California'],
            ['BCS', 'Baja California Sur'],
            ['CAM', 'Campeche	'],
            ['CHP', 'Chiapas'],
            ['CHH', 'Chihuahua'],
            ['COA', 'Coahuila	'],
            ['COL', 'Colima'],
            ['MEX', 'Estado de México'],
            ['DUR', 'Durango'],
            ['CDMX', 'Ciudad de Mexico'],
            ['GUA', 'Guanajuato'],
            ['GRO', 'Guerrero	'],
            ['HID', 'Hidalgo'],
            ['JAL', 'Jalisco'],
            ['MIC', 'Michoacán'],
            ['MOR', 'Morelos'],
            ['NAY', 'Nayarit'],
            ['NLE', 'Nuevo León'],
            ['OAX', 'Oaxaca'],
            ['PUE', 'Puebla'],
            ['QUE', 'Querétaro'],
            ['ROO', 'Quintana Roo	'],
            ['SLP', 'San Luis Potosí'],
            ['SIN', 'Sinaloa'],
            ['SON', 'Sonora'],
            ['TAB', 'Tabasco'],
            ['TAM', 'Tamaulipas'],
            ['TLA', 'Tlaxcala	'],
            ['VER', 'Veracruz	'],
            ['YUC', 'Yucatán'],
            ['ZAC', 'Zacatecas']
        ]
    },
    {
        country: 'us',
        states: [
            ['AL', 'Alabama'],
            ['AK', 'Alaska'],
            ['AZ', 'Arizona'],
            ['AR', 'Arkansas'],
            ['CA', 'California'],
            ['CO', 'Colorado'],
            ['CT', 'Connecticut'],
            ['DE', 'Delaware'],
            ['FL', 'Florida'],
            ['GA', 'Georgia'],
            ['HI', 'Hawaii'],
            ['ID', 'Idaho'],
            ['IL', 'Illinois'],
            ['IN', 'Indiana'],
            ['IA', 'Iowa'],
            ['KS', 'Kansas'],
            ['KY', 'Kentucky'],
            ['LA', 'Louisiana'],
            ['ME', 'Maine'],
            ['MD', 'Maryland'],
            ['MA', 'Massachusetts'],
            ['MI', 'Michigan'],
            ['MN', 'Minnesota'],
            ['MS', 'Mississippi'],
            ['MO', 'Missouri'],
            ['MT', 'Montana'],
            ['NE', 'Nebraska'],
            ['NV', 'Nevada'],
            ['NH', 'New Hampshire'],
            ['NJ', 'New Jersey'],
            ['NM', 'New Mexico'],
            ['NY', 'New York'],
            ['NC', 'North Carolina'],
            ['ND', 'North Dakota'],
            ['OH', 'Ohio'],
            ['OK', 'Oklahoma'],
            ['OR', 'Oregon'],
            ['PA', 'Pennsylvania'],
            ['RI', 'Rhode Island'],
            ['SC', 'South Carolina'],
            ['SD', 'South Dakota'],
            ['TN', 'Tennessee'],
            ['TX', 'Texas'],
            ['UT', 'Utah'],
            ['VT', 'Vermont'],
            ['VA', 'Virginia'],
            ['WA', 'Washington'],
            ['WV', 'West Virginia'],
            ['WI', 'Wisconsin'],
            ['WY', 'Wyoming']
        ]
    }
];