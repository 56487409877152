import AddressHelper from '../../utils/AddressHelper';
import StateNames from './StateNames';

export default class TomtomGeocodeService {
    constructor() {
        this.mapAddressItem = this.mapAddressItem.bind(this);
        this.getWeight = this.getWeight.bind(this);
    }

    doSearch(args, onResponse, onError) {
        if (!args) {
            onResponse([]);
            return;
        }

        const requestUrl = this.getRequestUrl(args);
        wo$.ajax({
            type: 'GET',
            url: requestUrl,
            success: response => {
                console.log(response);
                try {
                    const items = this.mapAddressItems(response, args);
                    return onResponse(items);
                }
                catch (ex) {
                    // HACK: Unable to serialise exception object
                    const exObj = JSON.parse(JSON.stringify(ex, Object.getOwnPropertyNames(ex)));
                    exObj.stack = ex.stack;

                    onError({
                        error: `Unable to map address results. ${ex.toString()}`,
                        exception: exObj
                    });
                }
            },
            error: (jqXHR, textStatus, errorThrown) => onError({
                error: {
                    jqXHR: jqXHR,
                    textStatus: textStatus,
                    errorThrown: errorThrown
                },
                requestUrl,
                args
            })
        });
    }

    getRequestUrl(args) {
        const requestTemplate =
            'https://api.tomtom.com/search/2/geocode/{query}.json?' +
            'countrySet={country}&' +
            'key={accessToken}&' +
            'typeahead=true&' +
            'limit=5&' +
            'language={language}';

        let url = requestTemplate.replace('{query}', this.cleanupSearchText(args.term))
            .replace('{country}', args.country)
            .replace('{language}', 'en-US')
            .replace('{accessToken}', args.apiKey);

        const proximity = this.getProximity(args.proximityLatLng);
        if (proximity) {
            url += `&lat=${proximity.lat}&lon=${proximity.lng}`;
        }

        const types = this.getTypes(args.types);
        if (types) {
            url += `&idxSet=${types}`;
        }

        return url;
    }

    // TODO: Extract to GeocodeServiceHelper
    cleanupSearchText(input) {
        const cleanedUp = input
            .substring(0, 50)
            .replace('/', ' ')
            // Remove 'unit'
            .replace(/^(unit\s+)(.+)$/i, '$2')
            // Remove unit number including the ones with letters (i.e. '100a')
            .replace(/^(\d+\w*\s+)(\d+.+)$/i, '$2')
            ;

        return encodeURIComponent(cleanedUp);
    }

    getProximity(latLng) {
        if (!latLng) {
            return null;
        }

        const center = {
            lat: latLng.lat.toFixed(3),
            lng: latLng.lng.toFixed(3)
        };

        return center;
    }

    // See: https://developer.tomtom.com/online-search/online-search-documentation-geocoding/structured-geocode#TypesAbbrv
    getTypes(items) {
        return !items || items.length === 0
            ? null
            : items.join(',');
    }

    mapAddressItems(response, args) {
        if (!response || !args) {
            return null;
        }

        const results = response.results;
        if (!results || results.length === 0) {
            return null;
        }

        const all = results
            .map(p => this.mapAddressItem(p, args.term.split(/\/|\s+/)))
            // Exclude country
            .filter(p => p.label !== p.country.longName)
            .sort(this.compare);

        const res = [];
        for (let i = 0; i < all.length; i++) {
            const element = all[i];
            if (res.filter(p => p.label === element.label).length === 0) {
                res.push(element);
            }
        }

        return res;
    }

    mapAddressItem(source, query) {
        // See: https://developer.tomtom.com/online-search/online-search-documentation-geocoding/structured-geocode

        const camelise = p =>
            p.replace(/(\w)(\w+)/g, (g0, g1, g2) => g1.toLowerCase() + g2);

        const getSegment = segmentName => {
            return source.address[segmentName] ||
                source.address[camelise(segmentName)];
        };

        const res = {
            placeId: source.id,
            longitude: source.position.lon,
            latitude: source.position.lat,
            relevance: source.score,
            source: source
        };

        const streetNumber = getSegment('streetNumber');
        res.streetNumber = this.getAddressComponent(streetNumber);
        if (res.streetNumber) {
            res.unit = this.getUnitComponent(query, streetNumber);
        }

        res.street = this.getStreetComponent(getSegment('streetName'));
        res.city = this.getCityComponent(
            getSegment('municipalitySubdivision') ||
            getSegment('municipality') ||
            getSegment('countrySecondarySubdivision'));

        res.postcode = this.getPostcodeComponent(getSegment('postalCode'));

        const countryCode = getSegment('countryCode');
        res.state = this.getStateComponent(getSegment('countrySubdivision'), countryCode);
        res.country = this.getAddressComponent(getSegment('country'), countryCode);

        res.label = AddressHelper.getLabel(res);
        res.weight = this.getWeight(res, query);
        return res;
    }

    // TODO: Extract to GeocodeServiceHelper
    getAddressComponent(longName, shortName) {
        if (!longName && !shortName) {
            return null;
        }

        return {
            longName: longName,
            shortName: shortName || longName,
        };
    }

    // TODO: Extract to GeocodeServiceHelper
    getUnitComponent(query, streetNumber) {
        const hasDigit = p => /\d+/.test(p);

        const firstNumberIndex = hasDigit(query[0]) ? 0 : 1;
        if (!hasDigit(query[firstNumberIndex + 1])) {
            // Query has only street number
            return null;
        }

        // Check if the unit comes second in the query
        const unit = query[firstNumberIndex] !== streetNumber
            ? query[firstNumberIndex]
            : query[firstNumberIndex + 1];

        return this.getAddressComponent(unit, unit);
    }

    // TODO: Extract to GeocodeServiceHelper
    getStreetComponent(text) {
        if (!text || text.length === 0) {
            return this.getAddressComponent(text);
        }

        const streetName = text.split(',')[0];

        return this.getAddressComponent(streetName);
    }

    getPostcodeComponent(text) {
        if (!text || text.length === 0) {
            return this.getAddressComponent(text);
        }

        const postcode = text.split(',')[0];

        return this.getAddressComponent(postcode);
    }

    getCityComponent(text) {
        if (!text || text.length === 0) {
            return this.getAddressComponent(text);
        }

        const cityName = text.split(',')[0];

        return this.getAddressComponent(cityName);
    }

    // TODO: Extract to GeocodeServiceHelper
    getStateComponent(text, country) {
        if (!text || text.length === 0) {
            return this.getAddressComponent(text);
        }

        // TomTom can return multiple states, for example 'New South Wales, Australian Capital Territory'
        const items = text.split(',');
        if (items.length === 0) {
            return this.getAddressComponent(text);
        }

        // The last one is more specific, so we use that
        const longName = items[items.length - 1].trim();
        const shortName = StateNames.abbreviate(longName, country);
        if (!shortName || shortName.length === 0) {
            return this.getAddressComponent(longName);
        }

        return this.getAddressComponent(longName, shortName);
    }

    // TODO: Extract to GeocodeServiceHelper
    getWeight(address, query) {
        const getAddressTypeOrder = address => {
            if (!address) {
                return 0;
            }

            const cleanUp = p => !p ? '' : p.toLowerCase().replace(/,|\s/, '');
            if (cleanUp(query.join(' ')) === cleanUp(address.label)) {
                return 100;
            }

            return address.unit
                ? 7
                : address.streetNumber
                    ? 6
                    : address.street
                        ? 5
                        : address.city
                            ? 4
                            : address.postcode
                                ? 3
                                : address.state
                                    ? 2
                                    : 1;
        };

        return getAddressTypeOrder(address) + address.relevance * 10;
    }

    // TODO: Extract to GeocodeServiceHelper
    compare(a, b) {
        // Order by weight descending
        return a.weight < b.weight
            ? 1
            : a.weight > b.weight
                ? -1
                : 0;
    }
}
