import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ClassNames from 'classnames';
import React, {
    forwardRef,
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useImperativeHandle,
    useReducer,
    useRef
} from 'react';
import type Autocomplete from 'react-autocomplete';

import type { Address, AddressPart } from '../../../../js/@types/Address';
import type { GetLocationError, GetLocationResult } from '../../../../js/@types/WhistleOutContext';
import { rollbar } from '../../../../js/common/rollbar';
import GeocodeService from './TomtomGeocodeService.js';

interface State {
    value?: string;
    resultsFor?: string;
    values: Address[];
    center?: GetLocationResult;
    shouldSelectFirst?: boolean;
    errorCount: number;
    setCenterErrorCount: number;
    error?: string;
}

interface Props {
    apiKey: string;
    country: string;
    inputClassName: string;
    inputElementId: string;
    inputProps: React.HTMLProps<HTMLInputElement>;
    autofocus?: boolean;
    onChange(e: React.ChangeEvent<HTMLInputElement>, value: string): void;
    onEmptyResults(error: string): void;
    onResponseError(error: GetLocationError): void;
    onSelect(address: Address): void;
    placeholder: string;
    queryDelay: number;
    searchTermMinLength: number;
    types: string[];
    value: string;
    wrapperProps?: React.HTMLProps<HTMLDivElement>;
}

interface AutocompleteItem {
    label: string;
    abbr: string;
    source: Address;
}

// TODO: Move to geocode service
interface GeocodeError {
    error: GetLocationError;
    requestUrl: string;
    args: GeocodeArgs;
}

// TODO: Move to geocode service
interface GeocodeArgs {
    term: string;
    country: string;
    apiKey: string;
    proximityLatLng: GetLocationResult;
    types: string[];
}

const { actions, reducer } = createSlice({
    name: 'address-autocomplete',
    initialState: {
        values: [],
        value: null,
        center: null,
        errorCount: 0,
        setCenterErrorCount: 0
    } as State,
    reducers: {
        onError: (state, action: PayloadAction<GeocodeError>) => {
            state.values = [];
            state.errorCount++;
            state.error = action.payload.error.textStatus;
        },
        onEmptyResults: (state, action: PayloadAction<{ resultsFor: string }>) => {
            state.resultsFor = action.payload.resultsFor;
            state.values = [];
            state.errorCount = 0;
            state.setCenterErrorCount = 0;
            state.error = null;
        },
        onSelect: (state, action: PayloadAction<string>) => {
            state.shouldSelectFirst = false;

            if (state.value === action.payload) {
                return;
            }

            state.value = action.payload;
            state.errorCount = 0;
            state.setCenterErrorCount = 0;
            state.error = null;
        },
        onSetCenterError: state => {
            state.setCenterErrorCount++;
        },
        onResult: (
            state,
            action: PayloadAction<{
                resultsFor: string;
                values: State['values'];
            }>
        ) => {
            const { resultsFor, values } = action.payload;

            state.resultsFor = resultsFor;
            state.values = values || [];

            state.errorCount = 0;
            state.setCenterErrorCount = 0;
            state.error = null;
        },
        processError: state => {
            state.errorCount = 0;
            state.setCenterErrorCount = 0;
        },
        requestSearch: (state, action: PayloadAction<{ shouldSelectFirst: boolean }>) => {
            state.shouldSelectFirst = action.payload.shouldSelectFirst;
        },
        setCenter: (state, action: PayloadAction<GetLocationResult>) => {
            state.center = action.payload;
        },
        setInputValue: (state, action: PayloadAction<string>) => {
            if (state.value === action.payload) {
                return;
            }

            state.value = action.payload;
            state.values = [];
            state.errorCount = 0;
            state.setCenterErrorCount = 0;
            state.error = null;
        }
    }
});

export interface AddressSearchHandle {
    focus: () => void;
    setValue: (value: string) => void;
    doSearch: (term?: string) => void;
    selectFirstSuggest: (term?: string) => void;
}

const geocodeService = new GeocodeService();
const ReactAutocomplete = lazy(() => import('react-autocomplete'));

const AddressAutocomplete = forwardRef<AddressSearchHandle, Props>((props, ref) => {
    const inputRef = useRef<HTMLInputElement>();

    const autocompleteRef = useCallback((element: Autocomplete) => {
        if (!element) {
            return;
        }
        inputRef.current = element.refs.input as HTMLInputElement;
    }, []);

    const [state, dispatch] = useReducer(reducer, {
        values: [],
        value: props.value,
        center: null,
        errorCount: 0,
        setCenterErrorCount: 0
    });

    const inputDelayTimeout = useRef<number>();
    const doSearchTimeout = useRef<NodeJS.Timeout>();

    const sendRollbarError = useCallback(
        (error: string, data: unknown) => {
            dispatch(actions.processError());
            rollbar.instance.error(error, {
                errorDetails: data,
                props: props,
                state: state
            });
        },
        [props, state]
    );

    const setCenter = useCallback(
        (callback: () => void) => {
            WhistleOut.getCurrentLatLng(
                latLng => {
                    if (
                        !props.country ||
                        !latLng.countryCode ||
                        (props.country || '').toLowerCase() === (latLng.countryCode || '').toLowerCase()
                    ) {
                        dispatch(actions.setCenter(latLng));
                    }
                    callback();
                },
                error => {
                    const errorCountLimit = 5;
                    dispatch(actions.onSetCenterError());
                    if (state.setCenterErrorCount > errorCountLimit) {
                        sendRollbarError('Error on setting geocode bias center', error);
                    }

                    if (callback) {
                        // Proceed with the callback on error
                        callback();
                    }
                }
            );
        },
        [props.country, sendRollbarError, state.setCenterErrorCount]
    );

    const focus = useCallback(() => {
        const input = inputRef.current;
        if (!input) {
            return;
        }

        dispatch(actions.setInputValue(input.value));

        if (document.activeElement !== input) {
            input.focus();
        }

        if (WhistleOut.isiOS()) {
            window.setTimeout(() => {
                input.setSelectionRange(0, 999);
            }, 3);
        } else {
            input.select();
        }
    }, []);

    const onInputFocus = useCallback(() => {
        focus();
    }, [focus]);

    const getItemValue = useCallback((item: AutocompleteItem) => {
        return item.label;
    }, []);

    const onResponseError = useCallback(
        (e: GeocodeError) => {
            dispatch(actions.onError(e));

            console.log('onResponseError: ', e);

            const errorCountLimit = 3;
            if (state.errorCount >= errorCountLimit) {
                sendRollbarError('Address autocomplete search error', e);
            }

            if (props.onResponseError) {
                props.onResponseError(e.error);
            }
        },
        [props, sendRollbarError, state.errorCount]
    );

    const onEmptyResults = useCallback(
        (resultFor: string, error: string) => {
            dispatch(actions.onEmptyResults({ resultsFor: resultFor }));

            if (props.onEmptyResults) {
                props.onEmptyResults(error);
            }
            console.log('onEmptyResults: ', error);
        },
        [props]
    );

    const onSearchResponse = useCallback(
        (values: Address[], term: string, shouldSelectFirst: boolean) => {
            dispatch(
                actions.onResult({
                    resultsFor: term,
                    values: values
                })
            );

            if (!values || values.length === 0) {
                if (shouldSelectFirst) {
                    onEmptyResults(term, 'Not found');
                }
                return;
            }

            if (shouldSelectFirst && props.onSelect) {
                console.log(`onSearchResponse -> props.onSelect: ${values[0].label}`);
                props.onSelect(values[0]);
            }
        },
        [onEmptyResults, props]
    );

    const doSearch = useCallback(
        (term: string, shouldSelectFirst?: boolean, initialise?: boolean) => {
            if (!initialise && !state.center) {
                setCenter(() => doSearch(term, shouldSelectFirst, true));
                return;
            }

            dispatch(actions.requestSearch({ shouldSelectFirst }));

            if (
                // Empty string
                !term ||
                term.length === 0 ||
                new RegExp('^\\s+$', 'i').test(term) ||
                // Min length
                term.length < (props.searchTermMinLength || 3)
            ) {
                dispatch(actions.onEmptyResults({ resultsFor: term }));
                return;
            }

            clearTimeout(doSearchTimeout.current);
            doSearchTimeout.current = setTimeout(
                () => {
                    const args: GeocodeArgs = {
                        term,
                        country: props.country,
                        apiKey: props.apiKey,
                        proximityLatLng: state.center,
                        types: props.types
                    };

                    geocodeService.doSearch(
                        args,
                        (results: Address[]) => onSearchResponse(results, term, shouldSelectFirst),
                        onResponseError
                    );
                },
                initialise ? 0 : 150
            );
        },
        [
            onResponseError,
            onSearchResponse,
            props.apiKey,
            props.country,
            props.searchTermMinLength,
            props.types,
            setCenter,
            state
        ]
    );

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
            const term = e.currentTarget.value;
            dispatch(actions.setInputValue(term));

            if (props.onChange) {
                props.onChange(e, value);
            }

            const trim = (p: string) => (p || '').trim().toLowerCase();

            clearTimeout(inputDelayTimeout.current);
            if (trim(state.resultsFor) !== trim(term)) {
                inputDelayTimeout.current = window.setTimeout(() => {
                    doSearch(term);
                }, props.queryDelay || 250);
            }
        },
        [doSearch, props, state]
    );

    const onSelect = useCallback(
        (value: string) => {
            dispatch(actions.onSelect(value));

            let address;
            if (!value) {
                address = null;
            } else {
                const matches = state.values.filter(p => p.label === value);
                if (!matches || matches.length === 0) {
                    return;
                }
                address = matches[0];
            }

            if (props.onSelect) {
                props.onSelect(address);
            }
        },
        [props, state]
    );

    useEffect(() => {
        const ensureFirstIsSelected = () => {
            if (state.shouldSelectFirst && state.values?.length > 0) {
                const label = state.values[0].label;
                if (label !== state.value) {
                    onSelect(label);
                }
            }
        };
        ensureFirstIsSelected();
    }, [onSelect, state.shouldSelectFirst, state.value, state.values]);

    const selectFirstSuggest = useCallback(
        (input?: string) => {
            const searchText = input || inputRef.current.value;
            if (!searchText) {
                onSelect('');
                return;
            }

            if (state.resultsFor?.trim().toLowerCase() === searchText.trim().toLowerCase()) {
                const values = state.values;
                if (values && values.length > 0) {
                    onSelect(values[0].label);
                    return;
                }
            }

            doSearch(searchText, true);
        },
        [doSearch, onSelect, state.resultsFor, state.values]
    );

    // TODO: Replace depricated keypress event
    const onKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.keyCode === 13) {
                window.setTimeout(() => {
                    selectFirstSuggest();
                }, 350); // Use time out to prevent duplicate search call
                event.preventDefault();
            }
        },
        [selectFirstSuggest]
    );

    const mapAutocompleteItem = useCallback((source: Address) => {
        return {
            label: source.label,
            abbr: source.source.id,
            source: source
        } as AutocompleteItem;
    }, []);

    // TODO: Implement  Consider using Mark.js
    // highlightTerms(element, keyword) {
    //     const options = {
    //         separateWordSearch: true,
    //         diacritics: true
    //     };

    //     // // Determine selected options
    //     // var options = {};
    //     // [].forEach.call(optionInputs, function (opt) {
    //     //     options[opt.value] = opt.checked;
    //     // });

    //     const target = new Mark(wo$(element));
    //     // Remove previous marked elements and mark
    //     // the new keyword inside the context
    //     target.unmark({
    //         done: function () {
    //             target.mark(keyword, options);
    //         }
    //     });
    // }

    const renderInput = useCallback(
        (p: React.HTMLProps<HTMLInputElement>) => {
            return (
                <input
                    {...p}
                    {...props.inputProps}
                    id={props.inputElementId}
                    className={ClassNames('form-control', props.inputClassName)}
                    placeholder={props.placeholder}
                    type="Text"
                    style={{
                        display: 'inline-block',
                        position: 'relative'
                    }}
                />
            );
        },
        [props.inputClassName, props.inputElementId, props.inputProps, props.placeholder]
    );

    const renderItem = useCallback((item: AutocompleteItem, isHighlighted: boolean) => {
        const getText = (p: AddressPart) => (p ? p.shortName : '');
        const address = item.source;

        return (
            <li
                className={ClassNames('pad-x-4 pad-y-3', { 'c-black': isHighlighted })}
                key={item.abbr}
                style={{
                    backgroundColor: isHighlighted ? '#f5f5f5' : null
                }}
            >
                {address.unit ? <span>{getText(address.unit)}/</span> : null}
                {address.streetNumber ? <span>{getText(address.streetNumber)}</span> : null}
                {address.streetNumber && address.street ? <span> </span> : null}
                {address.street ? <span>{getText(address.street)}</span> : null}
                {address.streetNumber || address.street ? <span>, </span> : null}
                <span className="c-gray-light">
                    {getText(address.city)}
                    {address.city ? <span> </span> : null}
                    {getText(address.state)}&nbsp;{getText(address.postcode)}
                </span>
            </li>
        );
    }, []);

    const renderMenu = useCallback((children: string[] | JSX.Element[]) => {
        return (
            // TODO: Replace inline styles with classes
            !children || children.length === 0 ? (
                <div />
            ) : (
                <div
                    className="autocomplete-menu bg-white c-black-80 box-shadow-2 rounded-2"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        top: `${inputRef.current.offsetHeight + 2}px`,
                        zIndex: 993,
                        cursor: 'pointer'
                    }}
                >
                    <ul className="list-unstyled mar-b-0 font-4">{children}</ul>
                </div>
            )
        );
    }, []);

    useEffect(() => {
        const input = inputRef.current;
        if (!input) {
            return;
        }

        input.addEventListener('focus', onInputFocus);
        input.addEventListener('keypress', onKeyPress, false);

        // HACK: Chrome doesn't honour autocomplete="off" on <input> elements, so we set it to a random string
        // see: https://stackoverflow.com/questions/25823448/ng-form-and-autocomplete-off/39689037#39689037
        // and https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off#38961567
        // Updated: Setting the value to something other than 'off' disables address auto fill as long as there is no form tag around it. If there is then it will show the text based auto fill
        input.setAttribute('autocomplete', 'nope');

        if (props.autofocus) {
            focus();
        }

        // TODO
        // highlightTerms(el, state.value);
        return () => {
            input.removeEventListener('focus', onInputFocus);
            input.removeEventListener('keypress', onKeyPress);
        };
    }, [focus, onInputFocus, onKeyPress, props.autofocus]);

    useImperativeHandle(ref, () => ({
        focus: () => focus(),
        setValue: (value: string) => {
            inputRef.current.value = value;
        },
        doSearch: (term: string) => selectFirstSuggest(term),
        selectFirstSuggest: (term: string) => selectFirstSuggest(term)
    }));

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ReactAutocomplete
                ref={autocompleteRef}
                wrapperProps={
                    props.wrapperProps || {
                        className: 'autocomplete-container',
                        style: {
                            position: 'relative'
                        }
                    }
                }
                wrapperStyle={{}}
                value={state.value}
                items={state.values.map(mapAutocompleteItem)}
                getItemValue={getItemValue}
                onSelect={onSelect}
                onChange={onChange}
                renderInput={renderInput}
                renderMenu={renderMenu}
                renderItem={renderItem}
            />
        </Suspense>
    );
});

AddressAutocomplete.displayName = 'AddressAutocomplete';
export default AddressAutocomplete;
