import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useReducer, useRef } from 'react';

import type { Address } from '../../../../js/@types/Address';
import type { GetLocationError } from '../../../../js/@types/WhistleOutContext';
import SearchAddressActions from '../../actions/SearchAddressActions';
import AddressHelper from '../../utils/AddressHelper';
import Popover from '../Popover';
import AddressAutocomplete, { AddressSearchHandle } from './AddressAutocomplete';
import MessageLabel, { MessageType } from './MessageLabel';

export type AddressChangedEventArgs = {
    sender: unknown;
    address: Address;
};

// TODO: Rename to event name
export const onAddressChangedEvent = 'AddressSearch.onAddressChanged';

export const dispatchAddressChangedEvent = (args: AddressChangedEventArgs) => {
    const event = new CustomEvent(onAddressChangedEvent, {
        detail: args
    });
    dispatchEvent(event);
};

export interface AddressSearchProps {
    key?: string;
    ref?: React.RefObject<AddressSearchHandle>;
    className?: string;
    inputClassName?: string;
    inputProps?: {
        'data-address-input'?: boolean;
        'data-address'?: boolean;
    } & React.HTMLProps<HTMLInputElement>;
    current: Address;
    countryCode: string;
    placeholder?: string;
    tooltipLink?: string | JSX.Element;
    tooltipDescription?: string;
    addressNotFoundMessage?: string;
    commonErrorMessage?: string;
    types: string[];
    queryDelay: number;
    apiKey: string;
    showProgress?: boolean;
    hideLabel?: boolean;
    autofocus?: boolean;
    skipGlobalUpdate?: boolean;
    onInput?: (value: string) => void;
    onChange?: (address: Address) => void;
    onEndProgress?: () => void;
    onInitialized?: (input: HTMLInputElement) => void;
    onEmptyResults?: (status: string) => void;
    onResponseError?: (error: GetLocationError) => void;
    onSearchClick?: () => void;
    onSelect?: () => void;
    onStartProgress?: () => void;
}

type Props = React.PropsWithChildren<AddressSearchProps>;

interface State {
    initialLabel: string;
    selected: Address;
    addressNotFound: boolean;
    isError: boolean;
    shouldSetInputText: boolean;
}

let counter = 0;

const { actions, reducer } = createSlice({
    name: 'address-autocomplete',
    initialState: {
        initialLabel: null,
        selected: null,
        addressNotFound: null,
        isError: false,
        shouldSetInputText: false
    } as State,
    reducers: {
        onEmptyResults: state => {
            state.selected = null;
            state.addressNotFound = true;
            state.isError = false;
            state.shouldSetInputText = false;
        },
        onInput: state => {
            state.selected = null;
            state.addressNotFound = false;
            state.isError = false;
        },
        onResponseError: state => {
            state.selected = null;
            state.addressNotFound = false;
            state.isError = true;
            state.shouldSetInputText = false;
        },
        onSetInputText: state => {
            state.shouldSetInputText = false;
        },
        setAddress: (state, action: PayloadAction<Address>) => {
            const address = action.payload;
            if (address?.label === state.initialLabel) {
                return;
            }

            state.initialLabel = address?.label;
            state.selected = address;
            state.addressNotFound = !address || address?.partialMatch;
            state.isError = false;
            state.shouldSetInputText = false;
        }
    }
});

const AddressSearch = forwardRef<AddressSearchHandle, Props>((props, ref) => {
    const autocompleteRef = useRef<AddressSearchHandle>(null);

    const id = useRef(counter++);
    const elementId = useRef(`enterAddressLocationInput-${id.current}`);

    const [state, dispatch] = useReducer(reducer, {
        initialLabel: props.current?.label,
        selected: props.current,
        addressNotFound: props.current?.partialMatch,
        isError: false,
        shouldSetInputText: false
    });

    useEffect(() => {
        dispatch(actions.setAddress(props.current || ({} as Address)));
    }, [props]);

    // TODO: Rewrite this, use state.isLoading
    const endProgress = useCallback(() => {
        if (props.onEndProgress) {
            props.onEndProgress();
        }
    }, [props]);

    const getInputElement = useCallback(() => {
        if (typeof window === 'undefined' || !window) {
            return null;
        }

        return document.getElementById(elementId.current) as HTMLInputElement;
    }, []);

    const onInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
            dispatch(actions.onInput());

            if (props.onInput) {
                props.onInput(value);
            }
        },
        [props]
    );

    const setInputText = useCallback((address: Address) => {
        dispatch(actions.onSetInputText());

        if (address && !address.label) {
            address.label = AddressHelper.getLabel(address);
        }

        const inputText = address ? address.label : null;
        autocompleteRef.current.setValue(inputText);
    }, []);

    const isEmpty = useCallback((address: Address) => {
        return (
            !address ||
            (!address.unit &&
                !address.streetNumber &&
                !address.street &&
                !address.city &&
                !address.postcode &&
                !address.state)
        );
    }, []);

    const onAddressSelect = useCallback(
        (address: Address) => {
            endProgress();

            if (isEmpty(address)) {
                address = null;
            }

            setInputText(address);

            if (address && AddressHelper.isEqual(state.selected, address)) {
                if (props.onSelect) {
                    props.onSelect();
                }
                return;
            }

            console.log('onAddressSelect', address);

            dispatch(actions.setAddress(address));

            if (!props.skipGlobalUpdate) {
                SearchAddressActions.setAddressCookie(address);
            }

            if (props.onChange) {
                props.onChange(address);
            }

            if (!props.skipGlobalUpdate) {
                dispatchAddressChangedEvent({
                    sender: elementId.current,
                    address: address
                });
            }

            if (props.onSelect) {
                props.onSelect();
            }
        },
        [endProgress, isEmpty, props, setInputText, state.selected]
    );

    const onAddressChangedEventHandler = useCallback(
        (e: CustomEvent<AddressChangedEventArgs>) => {
            if (!e.detail || e.detail.sender === elementId.current) {
                return;
            }

            setInputText(e.detail.address);

            if (AddressHelper.isEqual(state.selected, e.detail.address)) {
                return;
            }

            dispatch(actions.setAddress(e.detail.address));

            if (props.onChange) {
                props.onChange(e.detail.address);
            }
        },
        [props, setInputText, state]
    );

    const onEmptyResults = useCallback(
        (status: string) => {
            endProgress();

            console.log('onEmptyResults', status);
            dispatch(actions.onEmptyResults());

            // Reset Cookie
            SearchAddressActions.setAddressCookie(null);

            if (props.onEmptyResults) {
                props.onEmptyResults(status);
            }
        },
        [endProgress, props]
    );

    const onResponseError = useCallback(
        (error: GetLocationError) => {
            endProgress();

            console.log('onResponseError', error);
            dispatch(actions.onResponseError());

            // Reset Cookie
            SearchAddressActions.setAddressCookie(null);

            if (props.onResponseError) {
                props.onResponseError(error);
            }
        },
        [endProgress, props]
    );

    useEffect(() => {
        addEventListener(onAddressChangedEvent, onAddressChangedEventHandler);

        if (props.onInitialized) {
            const input = getInputElement();
            props.onInitialized(input);
        }

        return () => {
            removeEventListener(onAddressChangedEvent, onAddressChangedEventHandler);
        };
    }, [getInputElement, onAddressChangedEventHandler, props]);

    useImperativeHandle(ref, () => ({
        focus: autocompleteRef.current.focus,
        setValue: autocompleteRef.current.setValue,
        doSearch: autocompleteRef.current.doSearch,
        selectFirstSuggest: autocompleteRef.current.selectFirstSuggest
    }));

    const label = (state.selected || {}).label;
    return (
        <React.StrictMode>
            <React.Fragment>
                <div className={props.className}>
                    {
                        //
                        !props.hideLabel ? (
                            <label htmlFor={elementId.current} className="sr-only">
                                Location
                            </label>
                        ) : null
                    }
                    <AddressAutocomplete
                        //key={label}
                        ref={autocompleteRef}
                        inputElementId={elementId.current}
                        inputClassName={props.inputClassName}
                        inputProps={props.inputProps}
                        autofocus={props.autofocus}
                        placeholder={props.placeholder}
                        value={label}
                        country={props.countryCode}
                        types={props.types}
                        queryDelay={props.queryDelay}
                        searchTermMinLength={3}
                        apiKey={props.apiKey}
                        onChange={onInput}
                        onSelect={onAddressSelect}
                        onEmptyResults={onEmptyResults}
                        onResponseError={onResponseError}
                    />
                    {props.children}
                </div>
                {props.tooltipLink ? (
                    <Popover
                        container="body"
                        placement="top"
                        linkClassName="mar-t-2 mar-b-4 font-3 c-gray-light"
                        title={props.tooltipLink}
                        content={props.tooltipDescription}
                    />
                ) : null}
                {state.addressNotFound ? (
                    <MessageLabel type={MessageType.Warning} text={props.addressNotFoundMessage} />
                ) : null}
                {state.isError ? <MessageLabel type={MessageType.Error} text={props.commonErrorMessage} /> : null}
            </React.Fragment>
        </React.StrictMode>
    );
});

AddressSearch.displayName = 'AddressSearch';

/* eslint-disable import/no-named-as-default-member */
AddressSearch.propTypes = {
    countryCode: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    queryDelay: PropTypes.number,
    autofocus: PropTypes.bool,
    hideLabel: PropTypes.bool,
    addressNotFoundMessage: PropTypes.string,
    commonErrorMessage: PropTypes.string,
    showProgress: PropTypes.bool
};
/* eslint-enable */

AddressSearch.defaultProps = {
    types: ['geocode'],
    placeholder: 'Enter Street Address',
    addressNotFoundMessage: 'Address not found',
    commonErrorMessage: 'Unable to verify the address',
    queryDelay: 350,
    autofocus: false,
    showProgress: true
};

export default AddressSearch;
