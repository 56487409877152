import ClassNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

interface Props {
    type: MessageType;
    text: string;
}

export enum MessageType {
    Error = 'error',
    Warning = 'warning'
}

const MessageLabel = ({ type, text }: Props) => {
    if (isEmpty(text)) {
        return null;
    }

    const colour = type === MessageType.Error ? 'c-brand-danger' : 'c-brand-warning';

    return (
        <>
            <div className="row pad-y-3 pad-x-3 visible-sm visible-md visible-lg">
                <div className={ClassNames('col-xs-23 col-xs-offset-1', colour)}>
                    <span className="fa fa-exclamation-circle"></span> {text}
                </div>
            </div>
            <div className="row pad-y-3 pad-x-3 mar-t-neg-1 text-center visible-xs">
                <div className={ClassNames('col-xs-24', colour)}>
                    <span className="fa fa-exclamation-circle"></span> {text}
                </div>
            </div>
        </>
    );
};

export default MessageLabel;
