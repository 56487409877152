// TODO: Use https://react-bootstrap.github.io instead

import React, { useCallback, useEffect, useRef } from 'react';

interface Props {
    container: string | Element | false;
    content: string;
    title: string | JSX.Element;
    linkClassName: string;
    placement: 'auto' | 'top' | 'bottom' | 'left' | 'right';
}

const Popover = (props: Props) => {
    const ref = useRef<HTMLDivElement>();

    const handleMount = useCallback(() => {
        const container = wo$(ref.current);
        WhistleOut.applyPopover(container);
    }, []);

    useEffect(() => handleMount(), [handleMount]);

    return (
        <div ref={ref} className="mar-t-2 mar-b-4 font-3 c-gray-light">
            {props.title}
            {props.content ? (
                <span
                    data-toggle="popover"
                    data-container={props.container}
                    data-trigger="click"
                    data-placement={props.placement}
                    data-html="true"
                    data-content={props.content}
                >
                    {' '}
                    <i className="fa fa-info-circle"></i>
                </span>
            ) : null}
        </div>
    );
};

export default Popover;
